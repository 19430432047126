<template>
  <div class="app-tab-bar">
    <!-- 下方导航 -->
    <van-tabbar
      v-model="active"
      active-color="#000"
      inactive-color="#999"
      @change="onChange"
      shape="round"
      route
    >
      <van-tabbar-item to="/home">
        <div class="nav">
          <img
            v-if="$route.path === '/home'"
            src="../assets/images/home_active.png"
            alt=""
          />
          <img v-else src="../assets/images/home.png" alt="" />
          <p>首页</p>
        </div>
      </van-tabbar-item>
      <van-tabbar-item to="/category">
        <div class="nav">
          <img
            v-if="$route.path === '/category'"
            src="../assets/images/market_active.png"
            alt=""
          />
          <img v-else src="../assets/images/market.png" alt="" />
          <p>市场</p>
        </div>
      </van-tabbar-item>
      <van-tabbar-item to="/user">
        <div class="nav">
          <img
            v-if="$route.path === '/user'"
            src="../assets/images/user_active.png"
            alt=""
          />
          <img v-else src="../assets/images/user.png" alt="" />
          <p>我的</p>
        </div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'app-tab-bar',
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    onChange(m) {
      this.active = m;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-tabbar--fixed {
  z-index: 10000;
}
.nav {
  text-align: center;
  img {
    width: 20px;
    margin-bottom: 5px;
  }
}
</style>
