<template>
  <div id="app">
    <!-- 路由匹配到的组件将显示在这里 -->
    <keep-alive :include="['MessageNotification', 'Allorder', 'lickTeam']">
      <router-view :key="key"></router-view>
    </keep-alive>

    <!-- 底部tabbar 组件 -->
    <AppTabBar v-show="$route.meta.isShowTabbar"></AppTabBar>
  </div>
</template>

<script>
import AppTabBar from '@/components/AppTabBar';
export default {
  name: 'App',
  components: {
    AppTabBar,
  },
  computed: {
    key() {
      return this.$route.path + this.$store.state.isFromHome;
    },
  },
};
</script>

<style lang="less">
#app {
  position: relative;
  z-index: 9;
}
.app-tab-bar {
  position: relative;
  z-index: 9999;
}
.van-cell {
  background: transparent !important;
}
.allorder-box {
  .van-nav-bar__content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .van-tabs--line .van-tabs__wrap {
    position: fixed !important;
    top: 45px;
    left: 0;
    width: 100%;
  }
}
.van-number-keyboard__keys .van-key__wrapper:nth-child(10) {
  display: none;
}
.van-dialog__confirm {
  color: #0754d3 !important;
}
</style>
