import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
Vue.use(VueRouter);

// 配置项目路由
const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/home', // 重定向
    },
    {
      path: '/home', //首页
      name: 'Home',
      component: () => import('@/views/home/Home'),
      meta: {
        isShowTabbar: true,
      },
    },
    {
      path: '/category', //市场
      name: 'Category',
      component: () => import('@/views/category/Category'),
      meta: {
        isShowTabbar: true,
      },
    },
    {
      path: '/Bazaar', //市场详情
      name: 'Bazaar',
      component: () => import('@/views/category/Bazaar'),
    },
    {
      path: '/add', //跳转
      name: 'add',
      component: () => import('@/views/category/add'),
    },
    {
      path: '/user', //我的
      name: 'User',
      component: () => import('@/views/user/User'),
      meta: {
        isShowTabbar: true,
      },
    },
    {
      path: '/Share', //分享
      name: 'Share',
      component: () => import('@/views/user/set/Share'),
    },
    {
      path: '/Set', //设置
      name: 'Set',
      component: () => import('@/views/user/Set'),
    },

    {
      path: '/collection', //藏品详情
      name: 'collection',
      component: () => import('@/views/user/collection'),
    },
    {
      path: '/three', //3d转化
      name: 'three',
      component: () => import('@/views/user/three'),
    },
    {
      path: '/Binding', //设置--绑定第三方
      name: 'Binding',
      component: () => import('@/views/user/set/Binding'),
    },

    {
      path: '/Datum', //编辑资料
      name: 'Datum',
      component: () => import('@/views/user/set/Datum'),
    },
    {
      path: '/invite', //邀请设置
      name: 'invite',
      component: () => import('@/views/user/set/invite'),
    },
    {
      path: '/ourselves', //关于我们
      name: 'ourselves',
      component: () => import('@/views/user/set/ourselves'),
    },
    {
      path: '/agreement', //关于我们----协议
      name: 'agreement',
      component: () => import('@/views/user/set/agreement'),
    },
    {
      path: '/Safety', //安全中心
      name: 'Safety',
      component: () => import('@/views/user/set/Safety'),
    },
    {
      path: '/Autonym', //安全中心--实名认证
      name: 'Autonym',
      component: () => import('@/views/user/set/Autonym'),
    },
    {
      path: '/authorization', //安全中心--用户协议
      name: 'authorization',
      component: () => import('@/views/user/set/authorization'),
    },
    {
      path: '/Succeed', //安全中心--认证状态
      name: 'Succeed',
      component: () => import('@/views/user/set/Succeed'),
    },
    {
      path: '/Deal', //设置交易密码
      name: 'Deal',
      component: () => import('@/views/user/set/Deal'),
    },
    {
      path: '/Dealtow', //修改交易密码
      name: 'Dealtow',
      component: () => import('@/views/user/set/Dealtow'),
    },
    {
      path: '/Dealthree', //修改交易密码
      name: 'Dealthree',
      component: () => import('@/views/user/set/Dealthree'),
    },
    {
      path: '/Dealfour', //确认交易密码
      name: 'Dealfour',
      component: () => import('@/views/user/set/Dealfour'),
    },
    {
      path: '/Cipher', //设置登录密码
      name: 'Cipher',
      component: () => import('@/views/user/set/Cipher'),
    },
    {
      path: '/Ciphertwo', //设置登录密码
      name: 'Ciphertwo',
      component: () => import('@/views/user/set/Ciphertwo'),
    },
    {
      path: '/login', //登录
      name: 'Login',
      component: () => import('@/views/user/Login'),
    },
    {
      path: '/ma', //二维码
      name: 'ma',
      component: () => import('@/components/vue_qr/ma'),
    },
    {
      path: '/register', //注册
      name: 'Register',
      component: () => import('@/views/user/Register'),
    },
    {
      path: '/emailLogin', //登录
      name: 'EmailLogin',
      component: () => import('@/views/user/EmailLogin'),
    },
    {
      path: '/allorder', //全部订单
      name: 'Allorder',
      component: () => import('@/views/user/Allorder'),
    },
    {
      path: '/Allord', //全部订单
      name: 'Allord',
      component: () => import('@/views/user/Allord'),
    },
    {
      path: '/forget', //忘记密码
      name: 'forget',
      component: () => import('@/views/user/Forget'),
    },
    {
      path: '/forgetEmail', //忘记密码
      name: 'forgetEmail',
      component: () => import('@/views/user/ForgetEmail'),
    },

    {
      path: '/particulars', //商品详情
      name: 'particulars',
      component: () => import('@/views/home/particulars'),
    },
    {
      path: '/Order', //确认订单
      name: 'Order',
      component: () => import('@/views/home/Order'),
    },
    {
      path: '/OrderTow', //市场详情------确认订单
      name: 'OrderTow',
      component: () => import('@/views/category/OrderTow'),
    },
    {
      path: '/Banner', //banner详情
      name: 'Banner',
      component: () => import('@/views/home/Banner'),
    },
    {
      path: '/Announcement', //官方公告详情
      name: 'Announcement',
      component: () => import('@/views/home/Announcement'),
    },
    {
      path: '/unpaid', //待支付
      name: 'unpaid',
      component: () => import('@/views/user/unpaid'),
    },

    {
      path: '/win', //支付成功
      name: 'win',
      component: () => import('@/views/user/state/win'),
    },
    {
      path: '/Money', //我的钱包
      name: 'Money',
      component: () => import('@/views/user/Money'),
    },
    {
      path: '/Privacy', //用户隐私协议
      name: 'Privacy',
      component: () => import('@/views/user/Privacy'),
    },
    {
      path: '/topUp', //我的钱包---充值
      name: 'topUp',
      component: () => import('@/views/user/Money/topUp'),
      beforeEnter(to, from, next) {
        if (from.name) {
          localStorage.removeItem('paying');
        }
        next();
      },
    },
    {
      path: '/bank', //我的钱包---银行卡列表
      name: 'bank',
      component: () => import('@/views/user/Money/bank'),
    },
    {
      path: '/card', //我的钱包---绑定银行卡
      name: 'card',
      component: () => import('@/views/user/Money/card'),
    },
    {
      path: '/verification', //我的钱包---验证手机号
      name: 'verification',
      component: () => import('@/views/user/Money/verification'),
    },
    {
      path: '/Over', //再次输入密码
      name: 'Over',
      component: () => import('@/views/user/Money/Over'),
    },
    {
      path: '/win', //我的钱包---绑定银行卡成功
      name: 'win',
      component: () => import('@/views/user/Money/win'),
    },
    {
      path: '/withdraw', //我的钱包--提现
      name: 'withdraw',
      component: () => import('@/views/user/state/withdraw'),
    },
    {
      path: '/agre', //实名认证绑定协议
      name: 'agre',
      component: () => import('@/views/user/set/agre'),
    },
    {
      path: '/Alipay', //实名认证绑定协议
      name: 'Alipay',
      component: () => import('@/views/user/set/Binding/Alipay'),
    },
    {
      path: '/Pay', //实名认证绑定协议
      name: 'Pay',
      component: () => import('@/views/home/Pay.vue'),
    },
    {
      path: '/forgotPwd', //实名认证绑定协议
      name: 'forgotPwd',
      component: () => import('@/views/user/set/forgotPwd.vue'),
    },
    {
      path: '/searchResult', //实名认证绑定协议
      name: 'search',
      component: () => import('@/views/home/searchResult.vue'),
    },
    {
      path: '/search', //实名认证绑定协议
      name: 'search1',
      component: () => import('@/views/home/search.vue'),
    },
    {
      path: '/feedback', //实名认证绑定协议
      name: 'feedback',
      component: () => import('@/views/user/Feedback.vue'),
    },
    {
      path: '/merge', //实名认证绑定协议
      name: 'merge',
      component: () => import('@/views/user/merge/index.vue'),
    },
    {
      path: '/mergeRecord', //实名认证绑定协议
      name: 'mergeRecord',
      component: () => import('@/views/user/merge/mergeRecord.vue'),
    },
    {
      path: '/mergeDetail', //实名认证绑定协议
      name: 'mergeDetail',
      component: () => import('@/views/user/merge/detail.vue'),
    },
    {
      path: '/mergeContent', //实名认证绑定协议
      name: 'mergeContent',
      component: () => import('@/views/user/merge/merge.vue'),
    },
    {
      path: '/selectScience', //实名认证绑定协议
      name: 'selectScience',
      component: () => import('@/views/user/merge/selectScience.vue'),
    },
    {
      path: '/messageNotification', //实名认证绑定协议
      name: 'MessageNotification',
      component: () => import('@/views/home/MessageNotification.vue'),
    },
    {
      path: '/account', //实名认证绑定协议
      name: 'Account',
      component: () => import('@/views/user/account/index.vue'),
    },
    {
      path: '/sign', //实名认证绑定协议
      name: 'Sign',
      component: () => import('@/views/user/sign/index.vue'),
    },
    {
      path: '/keys', //实名认证绑定协议
      name: 'Keys',
      component: () => import('@/views/user/account/keys.vue'),
    },
    {
      path: '/cards', //实名认证绑定协议
      name: 'Cards',
      component: () => import('@/views/user/account/cards.vue'),
    },
    {
      path: '/redEnvelopes', //实名认证绑定协议
      name: 'RedEnvelopes',
      component: () => import('@/views/user/account/redEnvelopes.vue'),
    },
    {
      path: '/transferToBalance', //实名认证绑定协议
      name: 'TransferToBalance',
      component: () => import('@/views/user/account/transferToBalance.vue'),
    },
    {
      path: '/purchase', //实名认证绑定协议
      name: 'Purchase',
      component: () => import('@/views/purchase/index.vue'),
    },
    {
      path: '/purchaseDetail', //实名认证绑定协议
      name: 'PurchaseDetail',
      component: () => import('@/views/purchase/purchaseDetail.vue'),
    },
    {
      path: '/purchasePay', //实名认证绑定协议
      name: 'PurchasePay',
      component: () => import('@/views/purchase/purchasePay.vue'),
    },
    {
      path: '/commonProblem', //实名认证绑定协议
      name: 'commonProblem',
      component: () => import('@/views/user/commonProblem.vue'),
    },
    {
      path: '/luckDraw', //实名认证绑定协议
      name: 'luckDraw',
      component: () => import('@/views/luckDraw/luckDraw.vue'),
    },
    {
      path: '/specialActivity', //实名认证绑定协议
      name: 'specialActivity',
      component: () => import('@/views/specialActivity/index.vue'),
    },
    {
      path: '/guessing', //实名认证绑定协议
      name: 'guessing',
      component: () => import('@/views/specialActivity/guessing.vue'),
    },
    {
      path: '/lickTeam', //实名认证绑定协议
      name: 'lickTeam',
      component: () => import('@/views/specialActivity/lickTeam.vue'),
    },
    {
      path: '/useTeam', //实名认证绑定协议
      name: 'useTeam',
      component: () => import('@/views/specialActivity/useTeam.vue'),
    },
    {
      path: '/jackpot', //实名认证绑定协议
      name: 'jackpot',
      component: () => import('@/views/specialActivity/jackpot.vue'),
    },
    {
      path: '/collectionDetail', //实名认证绑定协议
      name: 'collectionDetail',
      component: () => import('@/views/user/collectionDetail.vue'),
    },
    {
      path: '/albumDetail', //实名认证绑定协议
      name: 'AlbumDetail',
      component: () => import('@/views/home/AlbumDetail.vue'),
    },
    {
      path: '/gameRanking', //实名认证绑定协议
      name: 'gameRanking',
      component: () => import('@/views/home/gameRanking.vue'),
    },
    {
      path: '/jiaoziChange', //实名认证绑定协议
      name: 'jiaoziChange',
      component: () => import('@/views/jiaoziChange/index.vue'),
    },
    {
      path: '/jiaoziChange', //实名认证绑定协议
      name: 'jiaoziChange',
      component: () => import('@/views/jiaoziChange/index.vue'),
    },
    {
      path: '/jiaoziChangeCenter', //实名认证绑定协议
      name: 'jiaoziChangeCenter',
      component: () => import('@/views/jiaoziChange/jiaoziChangeCenter.vue'),
    },
    {
      path: '/jiaoziChangeRecord', //实名认证绑定协议
      name: 'jiaoziChangeRecord',
      component: () => import('@/views/jiaoziChange/jiaoziChangeRecord.vue'),
    },
    {
      path: '/jiaoziChangeDetail', //实名认证绑定协议
      name: 'jiaoziChangeDetail',
      component: () => import('@/views/jiaoziChange/jiaoziChangeDetail.vue'),
    },
    {
      path: '/jiaoziChangeSelect', //实名认证绑定协议
      name: 'jiaoziChangeSelect',
      component: () => import('@/views/jiaoziChange/jiaoziChangeSelect.vue'),
    },
    {
      path: '/results', //实名认证绑定协议
      name: 'results',
      component: () => import('@/views/home/results.vue'),
    },
    {
      path: '/christmasLeaderboard', // 圣诞节排行榜
      name: 'christmasLeaderboard',
      component: () => import('@/views/christmasLeaderboard/index.vue'),
    },
  ],
});

// 路由前置守卫
router.beforeEach((to, from, next) => {
  if (
    from.name === 'Home' ||
    from.name === 'User' ||
    from.name === 'specialActivity'
  ) {
    store.commit('SET_ISFROMHOME', Math.random());
  }
  // 有token就表示已经登录
  // 想要进入购物车页面，必须有登录标识token
  let token = localStorage.getItem('token');
  if (to.path == '/cart') {
    // 此时必须要有token
    if (token) {
      next(); // next()去到to所对应的路由界面
    } else {
      Vue.prototype.$toast('请先登录');
      // 定时器
      setTimeout(() => {
        next('/user'); // 强制去到"/user"所对应的路由界面
      }, 1000);
    }
  } else {
    // 如果不是去往购物车的路由，则直接通过守卫，去到to所对应的路由界面
    next();
  }
});

// 该段代码不需要记，理解即可
// 在vue-router在3.1.0版本之后，push和replace方法会返回一个promise对象，如果跳转到相同的路由，就报promise uncaught异常
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
